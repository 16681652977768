import React from "react";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import LockIcon from "@material-ui/icons/Lock";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { gql } from "apollo-boost";

import NavigationItem from "./Item";
import ExternalNavigationItem from "./ExternalItem";
import { WithStyles } from "@material-ui/core";
import styles from "../App/styles";
import { useQuery } from "react-apollo-hooks";

const Navigation: React.FunctionComponent<
  WithStyles<typeof styles> & {
    open: boolean;
    handleClose: () => void;
  }
> = ({ classes, open, handleClose }) => {
  const { loading, error, data } = useQuery(gql`
    query {
      redirectUrl {
        url
      }
    }
  `);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error :(</div>;
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        )
      }}
      open={open}
    >
      <List className={classes.list}>
        <NavigationItem
          to="/ledger"
          label="Ledger - Update"
          onClick={handleClose}
          button={false}
        >
          <AssessmentIcon />
        </NavigationItem>
        <NavigationItem
          to="/ledger-db"
          label="Ledger - Cached"
          onClick={handleClose}
          button={false}
        >
          <AssessmentIcon />
        </NavigationItem>
        <NavigationItem
          to="/ledger-by-character"
          label="Ledger"
          onClick={handleClose}
          button={false}
        >
          <AssessmentIcon />
        </NavigationItem>
        <NavigationItem
          to="/characters"
          label="Characters"
          onClick={handleClose}
          button={false}
        >
          <AssessmentIcon />
        </NavigationItem>
        <NavigationItem
          to="/tax-overview"
          label="Tax Overview"
          onClick={handleClose}
          button={false}
        >
          <AssessmentIcon />
        </NavigationItem>
        <ExternalNavigationItem
          href={data.redirectUrl.url}
          label="Log In"
          onClick={handleClose}
          button={false}
        >
          <LockIcon />
        </ExternalNavigationItem>
      </List>
    </Drawer>
  );
};

export default Navigation;
