import React, { lazy, Suspense, FunctionComponent } from "react";
import { CircularProgress } from "@material-ui/core";
import { Router, RouteComponentProps } from "@reach/router";
import Auth from "../Auth";

const Ledger = lazy(() => import("../../pages/Ledger"));
const LedgerDB = lazy(() => import("../../pages/LedgerDB"));
const ByCharacter = lazy(() => import("../../pages/ByCharacter"));
const Characters = lazy(() => import("../../pages/Characters"));
const TaxOverview = lazy(() => import("../../pages/TaxOverview"));

const Route: FunctionComponent<
  { component: FunctionComponent } & RouteComponentProps
> = ({ component: Component, ...rest }) => <Component {...rest} />;

const RouterSuspense: FunctionComponent<RouteComponentProps> = ({
  children
}) => <Suspense fallback={<CircularProgress />}>{children}</Suspense>;

const Routes = () => (
  <Router>
    <RouterSuspense default>
      <Route path="/ledger" component={Ledger} />
      <Route path="/ledger-db" component={LedgerDB} />
      <Route path="/ledger-by-character" component={ByCharacter} />
      <Route path="/characters" component={Characters} />
      <Route path="/tax-overview" component={TaxOverview} />
      <Route path="/oauth/callback" component={Auth} />
    </RouterSuspense>
  </Router>
);

export default Routes;
