import * as React from "react";
import { Redirect } from "@reach/router";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo-hooks";
import { parse } from "query-string";

const Auth: React.FunctionComponent = () => {
  const { code } = parse(window.location.search);
  // const getAuthentication = useMutation(
  //   gql`
  //     mutation login($code: String!) {
  //       login(code: $code) {
  //         token
  //       }
  //     }
  //   `,
  //   { variables: { code } }
  // );

  // React.useEffect(() => {
  //   getAuthentication();
  // }, []);

  const { data, error, loading } = useQuery(
    gql`
      query($code: String!) {
        login(code: $code)
      }
    `,
    { variables: { code } }
  );

  if (loading) {
    return <h2>Loading...</h2>;
  }

  if (error) {
    console.error(error);
    return <pre>{JSON.stringify(error)}</pre>;
  }
  window.localStorage.setItem("token", data.login);

  return <Redirect to="/" noThrow />;
};

export default Auth;
