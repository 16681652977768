import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import LightModeIcon from "@material-ui/icons/Brightness5";
import DarkModeIcon from "@material-ui/icons/Brightness5Outlined";
import styles from "../App/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";

const Header: React.FunctionComponent<
  {
    open: boolean;
    handleOpen: () => void;
    toggleDarkMode: (darkMode: boolean) => void;
    id: string;
  } & WithStyles<typeof styles>
> = ({ classes, open, handleOpen, id, toggleDarkMode }) => (
  <AppBar id={id} position="absolute" className={classes.appBar}>
    <Toolbar disableGutters={!open} className={classes.toolbar}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleOpen}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" color="inherit" noWrap className={classes.title}>
        EVE Mining Manager
      </Typography>
      <IconButton color="inherit" onClick={() => toggleDarkMode(false)}>
        <LightModeIcon />
      </IconButton>
      <IconButton color="inherit" onClick={() => toggleDarkMode(true)}>
        <DarkModeIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
);

export default Header;
