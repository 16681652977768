import React, { Fragment, useState } from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Header from "../Header";
import Navigation from "../Navigation";
import styles from "./styles";
import Routes from "./Routes";

const App: React.FunctionComponent<
  WithStyles<typeof styles> & { toggleDarkMode: (darkMode: boolean) => void }
> = ({ classes, toggleDarkMode }) => {
  const [drawerState, toggleDrawer] = useState(false);

  return (
    <Fragment>
      <div className={classes.root}>
        <Header
          id="app-header"
          classes={classes}
          open={drawerState}
          handleOpen={() => toggleDrawer(!drawerState)}
          toggleDarkMode={toggleDarkMode}
        />
        <Navigation
          classes={classes}
          open={drawerState}
          handleClose={() => toggleDrawer(false)}
        />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.page}>
            <Routes />
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(App);
